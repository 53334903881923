import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import numeral from 'numeral'
import Vue from 'vue'
import moment from 'moment'
import App from './App'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.filter('numberFormat', value => numeral(value).format('0,0.'))
Vue.filter('percentFormat', value => numeral(value).format('0,0.00'))
Vue.filter('capitalize', value => {
  let rValue = value
  if (!value) return ''
  rValue = value.toString()

  return rValue.charAt(0).toUpperCase() + rValue.slice(1)
})

Vue.filter('formatDate', value => moment(value).format('DD/MM/YYYY HH:mm:ss'))
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
