import axios from 'axios'
import Cookies from 'js-cookie'
import environment from '@/environments/environment'

const path = environment.apiUrl

export default {
  getToken() {
    return Cookies.get('tiuser')
  },
  getUserName() {
    return Cookies.get('userName')
  },
  isLoggin() {
    return Cookies.get('tiuser') !== undefined
  },
  setUserLogged(userLogged, userName) {
    const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000)
    Cookies.set('tiuser', userLogged, { expires: inFifteenMinutes })
    Cookies.set('userName', userName)
  },
  getUserLogged() {
    return Cookies.get('tiuser')
  },
  deleteUserLogged() {
    Cookies.remove('tiuser')
  },
  login(user, password) {
    const userPost = { user, password }

    return axios.post(`${path}/signin`, userPost)
  },
  logout() {
    if (this.getToken) {
      Cookies.remove('tiuser')
    }
  },
}
