<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/ti-branch-min.png')"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-2"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            Tequila Inteligente
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-section-title
        title="Dashboards"
        two-line
      ></nav-menu-section-title>
      <nav-menu-link
        title="Inicio"
        :to="{ name: 'home' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isLoggin"
        title="SEGGITUR"
        :to="{ name: 'seggitur' }"
        :icon="icons.mdiMap"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isLoggin"
        title="Movilidad"
        :to="{ name: 'movilidad' }"
        :icon="icons.mdiCar"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isLoggin"
        title="Analítica de Carga"
        :to="{ name: 'analiticaCarga' }"
        :icon="icons.mdiGoogleAnalytics"
      ></nav-menu-link>
      <nav-menu-link
        title="Datos Abiertos"
        :to="{ name: 'datosAbiertos' }"
        :icon="icons.mdiFileDocumentMultiple"
      ></nav-menu-link>
      <nav-menu-link
        title="Lugares de Estacionamiento"
        :to="{ name: 'parking' }"
        :icon="icons.mdiMapClockOutline"
      ></nav-menu-link>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          v-if="!isLoggin"
          color="primary"
          block
          @click="goLogin"
        >
          Login
        </v-btn>
      </div>

      <div class="pa-2">
        <v-btn
          v-if="isLoggin"
          color="primary"
          block
          @click="goLogout"
        >
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiCar,
  mdiBottleTonicPlus,
  mdiMonitorDashboard,
  mdiCardAccountDetailsOutline,
  mdiHospitalBuilding,
  mdiCardBulletedOutline,
  mdiGauge,
  mdiMapClockOutline,
  mdiGoogleAnalytics,
  mdiMap,
  mdiAccountGroup,
  mdiCloud,
  mdiMessage,
  mdiHumanGreetingProximity,
  mdiFileDocumentMultiple,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle'
import NavMenuLink from './components/NavMenuLink'
import auth from '@/services/auth'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiCar,
        mdiBottleTonicPlus,
        mdiMonitorDashboard,
        mdiCardAccountDetailsOutline,
        mdiHospitalBuilding,
        mdiCardBulletedOutline,
        mdiGauge,
        mdiMapClockOutline,
        mdiGoogleAnalytics,
        mdiMap,
        mdiAccountGroup,
        mdiCloud,
        mdiMessage,
        mdiHumanGreetingProximity,
        mdiFileDocumentMultiple,
      },
    }
  },
  computed: {
    isLoggin() {
      return auth.isLoggin()
    },
  },
  methods: {
    goLogin() {
      this.$router.push('/pages/login')
    },
    goLogout() {
      auth.logout()
      if (this.$route.path !== '/home') {
        this.$router.push('/home').then(() => {
          window.location.reload()
        })
      } else {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
