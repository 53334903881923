import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    movilidad: {
      fecha: {
        inicio: '',
        fin: '',
      },
    },
  },
  mutations: {
    movilidadSetFecha(state, fechas) {
      state.movilidad.fecha = fechas
    },
  },
  getters: {
    getFechasMovilidad: state => state.movilidad.fecha,
  },
  actions: {},
  modules: {},
})
